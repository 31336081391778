import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, inject } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Observable, filter, map, mergeMap, shareReplay } from 'rxjs';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  private breakpointObserver = inject(BreakpointObserver);
  public showSideNav: boolean = true;

  environment = environment;

  constructor(router: Router, route: ActivatedRoute) {

    // listen to sidenav variable from router events
    router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      map(() => route),
      map(route => {
        while (route.firstChild) route = route.firstChild
        return route
      }),
      filter(route => route.outlet === 'primary'),
      mergeMap(route => route.data)
    ).subscribe(data => 
      this.showSideNav = !(data['hideSideNav']) ?? true
    )
  }

  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
      map(result => result.matches),
      shareReplay()
    );
}

import { Injectable, inject } from '@angular/core';
import { GoogleAuthProvider, getAuth, signInWithPopup, OAuthProvider, Auth, user, User } from '@angular/fire/auth';
import { Router } from '@angular/router';
import { Subscription, lastValueFrom, map, take } from 'rxjs';
import { UserService } from './user.service';
import { PersonalityModelService } from './personality-model.service';
import { ContentTypeService } from './content-type.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private auth: Auth = inject(Auth);
  private personalityModelService: PersonalityModelService = inject(PersonalityModelService);
  private contentTypeService: ContentTypeService = inject(ContentTypeService);
  user$ = user(this.auth);
  userSubscription!: Subscription;

  // It appears that you need to inject the auth instance of angularfire in your service.
  constructor(private userService: UserService, private router: Router) {
    this.userSubscription = this.user$.subscribe((aUser: User | null) => {
      if (aUser) {
        this.userService.updateUser( aUser.email!,
          {
            displayName: aUser.displayName,
            photoURL: aUser.photoURL,
            uid: aUser.uid
          }
        );
        // prefetch core data
        // TODO is this correct place?
        this.personalityModelService.getPersonalityModels().pipe(take(1)).subscribe();
        this.contentTypeService.getContentTypes().pipe(take(1)).subscribe();
      }
    })
  }
  loginWithGoogle(): Promise<void> {
    const provider = new GoogleAuthProvider();
    return this.loginWithAny(provider);
  }

  loginWithMicrosoft(): Promise<void> {
    const provider = new OAuthProvider('microsoft.com');
    return this.loginWithAny(provider);
  }

  loginWithAny(provider: GoogleAuthProvider | OAuthProvider): Promise<void> {
    const auth = getAuth();
    return new Promise((resolve, reject) => {
      signInWithPopup(auth, provider)
        .then((result) => {
          // This gives you a Access Token. You can use it to access the Google API.
          //const credential = provider.credentialFromResult(result);
          //const token = credential?.accessToken;
          // The signed-in user info.
          //const user = result.user;
          // IdP data available using getAdditionalUserInfo(result)
          // ...
          //console.log(user);
          resolve();
        }).catch((error) => {
          // Handle Errors here.
          //const errorCode = error.code;
          //const errorMessage = error.message;
          // The email of the user's account used.
          //const email = error.customData.email;
          // The AuthCredential type that was used.
          reject(error.code);
        });
    });
  }

  logout(): void {
    const auth = getAuth();
    auth.signOut().then(() => {
      console.log('Sign out successful');
      this.router.navigateByUrl('/login');
    }, function (error) {
      console.error(error);
    });
  }

  getCurrentUID(): Promise<string> {
    return lastValueFrom(user(this.auth).pipe(map(user => user!.uid), take(1)));
  }
}

<div class="flex-columns">
    <div class=" column left-column">
        <div class="flex-container">
            <div class="container">
                <div class="logo-container container">
                    <img src="assets/img/VizarAI_logo_kleur-naamR_crop.png" />
                </div>

                <div class="buttons-container container">
                    <button mat-button class="login-with-x-btn google-button" (click)="loginWithGoogle()" i18n>
                        Sign in with Google
                    </button>

                    <button mat-button type="button" class="login-with-x-btn ms-button" (click)="loginWithMicrosoft()"
                        i18n>
                        Sign in with Microsoft
                    </button>
                </div>
            </div>
            <div class="error-message" *ngIf="message">
                {{ message }}
            </div>
        </div>
    </div>
    <div class="column right-column">
        <div class="flex-container">
            <div class="container">
                <h1 class="welcome-title">Welcome to <img class="logo" src="assets/img/VizarAI_logo_wit-naamR.png" />
                </h1>
                <h2 class="welcome-subtitle">Want to know more about the product?</h2>
                <div>
                    <a class="website-button" mat-flat-button target="_blank" href="https://vizar.ai/" i18n>Visit our
                        website</a>
                </div>
            </div>
        </div>
    </div>
</div>
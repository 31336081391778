import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../share/services/auth.service';

@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss']
})
export class AuthComponent {
  message: string = '';

  constructor(private authService: AuthService, private router: Router) {
  }

  loginWithGoogle() {
    this.authService.loginWithGoogle().then(() => {
      this.handleRedirect();
    }).catch(error => {
      this.handleError(error);
    });
  }

  loginWithMicrosoft() {
    this.authService.loginWithMicrosoft().then((result) => {
      this.handleRedirect();
    }).catch(errorCode => {
      this.handleError(errorCode);
    });;
  }

  handleError(errorCode: string) {
    console.log(errorCode);
    if (errorCode === 'auth/account-exists-with-different-credential') {
      this.message = $localize`The email address you are trying to log in with is already in use with another provider. Try another login method with this email address.`;
    } else {
      // check later for specific error, will return 47 for now (BUG): https://issuetracker.google.com/issues/210093260?pli=1
      this.message = $localize`Your account is not yet known in our system, or another internal error occured. Please contact support to resolve this issue.`;
    }
  }

  handleRedirect() {
    this.router.navigateByUrl(sessionStorage.getItem('redirectUrl') || '/');
  }

  logout() {
    this.authService.logout();
  }

  goToWebsite() {
    window.open('//https://vizar.ai/', "_blank");
  }
}

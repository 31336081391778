export const environment = {
    firebase: {
        projectId: 'vizar-sandbox',
        apiKey: "AIzaSyBqVuODlgNxYAJiEUVEw_dYBgXFPx0qmj4",
        authDomain: "vizar-sandbox.firebaseapp.com",
    },
    production: false,
    apiUrl: 'https://vizar-back-end-7phudbpj5q-ew.a.run.app/',
    organizationIdTemp: '8680cd6b-f781-11ee-8a56-42010a400002',
    personalityModelIdTemp: 'd444b9a2-f781-11ee-8a56-42010a400002'
};
<mat-sidenav-container class="sidenav-container">
  <mat-sidenav #drawer class="sidenav" fixedInViewport
      [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'"
      [mode]="(isHandset$ | async) ? 'over' : 'side'"
      [opened]="showSideNav && (isHandset$ | async) === false">
    <a routerLink="/my-vizar" >
      <img class="logo" src="assets/img/VizarAI_logo_kleur-naamR_crop.png" />
    </a>
    <mat-nav-list>
      <a mat-list-item routerLink="/my-vizar" >
        <span matListItemIcon>
          <mat-icon class="material-symbols-outlined">home</mat-icon>
        </span>
        <span matListItemLine i18n>My Vizar</span>
      </a>
      <a mat-list-item routerLink="/topics" >
        <span matListItemIcon>
          <mat-icon class="material-symbols-outlined">bolt</mat-icon>
        </span>
        <span matListItemLine i18n>Topics</span>
      </a>
      <a mat-list-item routerLink="/models/{{environment.personalityModelIdTemp}}" >
        <span matListItemIcon>
          <mat-icon class="material-symbols-outlined">segment</mat-icon>
        </span>
        <span matListItemLine i18n>Personalities</span>
      </a>
      <a class="disabled" mat-list-item>
        <span matListItemIcon>
          <mat-icon class="material-symbols-outlined">account_circle</mat-icon>
        </span>
        <span matListItemLine i18n>Profile</span>
      </a>
      <a class="disabled" mat-list-item>
        <span matListItemIcon>
          <mat-icon class="material-symbols-outlined">lightbulb</mat-icon>
        </span>
        <span matListItemLine i18n>Admin</span>
      </a>
      <!--<a *ngFor="let route of routes"
         mat-list-item  
         [routerLink]="route.path" 
         (click)="sidenav.toggle()"> {{route.label}}</a>-->
    </mat-nav-list>
  </mat-sidenav>
  <mat-sidenav-content>
    <mat-toolbar color="primary" *ngIf="showSideNav && (isHandset$ | async)">
      <button
        type="button"
        aria-label="Toggle sidenav"
        mat-icon-button
        (click)="drawer?.toggle()">
        <mat-icon class="material-symbols-outlined" i18n>menu</mat-icon>
      </button>
      <span i18n>Menu</span>
    </mat-toolbar>
    <router-outlet></router-outlet>
  </mat-sidenav-content>
</mat-sidenav-container>


import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable, first, map, shareReplay } from 'rxjs';
import { ContentType } from 'src/app/core/models/content-type';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ContentTypeService {

  private http: HttpClient = inject(HttpClient);

  private cachedPersonalityModels$: Observable<ContentType[]> | undefined;

  // cache: https://medium.com/@AurelienLeloup/cache-http-requests-with-rxjs-for-angular-eb9bee93824d
  // TODO eventually cache invalidator improvement. now refresh will do

  constructor() { }

  public getContentTypes() {

    if (!this.cachedPersonalityModels$) {
      // TODO!!! hardcoded organization for now
      const orgIdEnv = environment.organizationIdTemp;
      const options = { params: new HttpParams().set('organizationId', orgIdEnv) };
      this.cachedPersonalityModels$ = this.http.get<ContentType[]>(environment.apiUrl + '/content-types', options).pipe(
        first(),
        map(processes => {
          return processes.map(contentType => {
            return <ContentType>{
              contentTypeId: contentType.contentTypeId,
              name: contentType.name
            }
          });
        }),
        shareReplay(1)
      );
    }
    return this.cachedPersonalityModels$;
  }
}

import { Injectable, inject } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable, switchMap, take } from 'rxjs';
import { Auth, idToken } from '@angular/fire/auth';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  private auth: Auth = inject(Auth);
  idToken$ = idToken(this.auth);

  constructor() {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return this.idToken$.pipe(
       take(1), // Only emit the first value!
       switchMap((token: any) => {
         if (token) {
           request = request.clone({
             setHeaders: { Authorization: `Bearer ${token}` }
           });
         }
         return next.handle(request);
       })
 
     );
 }
}

import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { first } from 'rxjs';

import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  private http: HttpClient = inject(HttpClient);

  constructor() { }

  updateUser(email: string, input: { displayName: string | null, photoURL: string | null, uid: string }) {
    // response silent
    this.http.put(environment.apiUrl + '/users/email/' + email, { data: input }).pipe(first()).subscribe();
  }

  getUsername(uuid: string) {
    return this.http.get(environment.apiUrl + '/users/' + uuid);
  }
}

/// <reference types="@angular/localize" />

import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';

import { environment } from './environments/environment';

// disable console.log in prd
if (environment.production) {
  window.console.log = () => { }
}


platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
